<template lang='pug'>
  .bg-white.rounded-lg.px-4.py-2(style='width: 500px;')
    .text-xl.font-bold Настройка страны

    .flex.flex-col.gap-2.mt-4
      .text-sm.leading-none Код-номер страны
      input.w-full(v-model='form.country_code')
      .text-sm.leading-none Название страны
      input.w-full(v-model='form.country_name')
      p.text-sm.leading-none Номер телефона
      input.w-full(v-model='form.dialling_code')

    .mt-4
      button.bg-green-500.text-white(@click='save()') Сохранить
      button.pl-2(@click='discard()') Отменить
</template>

<script>
export default {
  props: {
    country: {
      type: Object,
      required: true
    },
    onSave: {
      type: Function,
      required: true
    }
  },
  mounted() {
    Object.assign(this.form, this.country);
  },
  data() {
    return {
      form: {
        country_code: null,
        country_name: null,
        dialling_code: null
      }
    }
  },
  methods: {
    save() {
      this.onSave(this.form);
      
      this.$root.closeDialog();
    },
    discard() {
      this.$root.closeDialog();
    }
  }
}
</script>

<style>

</style>